import { Close } from '../../../Icons';
import { Spinner } from '../../../Feedback/Spinner';
import { ItemWrapper, LabelWrapper, ListItemWrapper } from './style';

export type ListItemProps = {
  label: string;
  itemBefore?: React.ReactNode;
  itemAfter?: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  loading?: boolean;
  error?: boolean;
};

export const ListItem = ({
  label,
  itemBefore,
  itemAfter,
  disabled,
  onClick,
  loading,
  error,
}: ListItemProps) => {
  const handleClick = () => {
    if (!!disabled || !!loading) {
      return;
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <ListItemWrapper disabled={!!disabled} onClick={handleClick}>
      {itemBefore && <ItemWrapper>{itemBefore}</ItemWrapper>}
      <LabelWrapper>{label}</LabelWrapper>

      {loading && <Spinner size="small" />}
      {error && !loading && <Close color="red" />}
      {itemAfter && <ItemWrapper>{itemAfter}</ItemWrapper>}
    </ListItemWrapper>
  );
};
