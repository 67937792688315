import { useEffect, useMemo, useState } from 'react';
import { Close, colors, Menu, spacing } from 'component-library';
import { useNavigate } from 'react-router-dom';
import { co2ImpactroutePaths } from '../../../lib/routePaths/co2ImpactRoutePaths';
import { routePathsAdmin } from '../../../lib/routePaths/adminRoutePaths';
import { type BasicProfile } from '../../Profile';
import {
  getCo2ImpactUrl,
  getOnboardingUrl,
  getServiceUrl,
  getStartUrl,
} from '../../utils/environment';
import {
  getUsersServices,
  userIsUserAdminForAnyPartner as _userIsAdminForAnyPartner,
} from '../../utils/services';

import {
  MenuButton,
  MenuButtonWrapper,
  MobileMenuFoldout,
  MobileMenuFoldoutList,
  MobileMenuFoldoutListItem,
  MobileMenuLink,
  MobileMenuWrapper,
} from './styles';
import type { MenuService } from '../../Services';
import type { TextType } from '../types';

type MobileMenuProps = {
  profile: BasicProfile | null;
  allServices?: MenuService[];
  activeLink?: string;
  text: TextType;
  serviceNames?: Record<string, string>;
};
export const MobileMenu = ({
  profile,
  allServices = [],
  activeLink,
  serviceNames,
  text,
}: MobileMenuProps) => {
  const [showMenu, setShowMenu] = useState(false);
  const [userServices, setUserServices] = useState<MenuService[]>();
  const [userIsAdminForAnyPartner, setUserIsAdminForAnyPartner] = useState(false);
  const [userHasSwedishpartner, setUserHasSwedishpartner] = useState(false);

  useEffect(() => {
    if (allServices.length && profile) {
      setUserServices(getUsersServices(profile, allServices));
      setUserIsAdminForAnyPartner(_userIsAdminForAnyPartner(profile));
      setUserHasSwedishpartner(profile.aggregatedPermissions.markets.sweden);
    }
  }, [profile, allServices]);

  const navigate = useNavigate();

  const toggleMenu = () => {
    const body = document.querySelector('body');

    if (body) {
      body.style.overflow = showMenu ? 'auto' : 'hidden';
    }
    setShowMenu(!showMenu);
  };

  const menuLinks = useMemo(() => {
    if (!userServices || !profile) {
      return [];
    }

    const links = userServices.map((service) => ({
      linkText: serviceNames?.[service.name] ?? service.name,
      link: getServiceUrl(service),
      onLinkClick: () => {
        navigate(getServiceUrl(service));
      },
      active: service.name === activeLink,
    }));

    links.unshift({
      linkText: text.homeLink,
      link: getStartUrl(),
      onLinkClick: () => {
        navigate('/');
      },
      active: 'touchpointx' === activeLink,
    });

    if (userHasSwedishpartner && profile.aggregatedPermissions.services.followUp) {
      links.push({
        linkText: text.co2Impact,
        link: getCo2ImpactUrl(),
        onLinkClick: () => {
          navigate(co2ImpactroutePaths.HOME);
        },
        active: 'co2_impact' === activeLink,
      });
    }

    if (userIsAdminForAnyPartner) {
      links.push({
        link: getOnboardingUrl(),
        linkText: text.userManagement,
        active: 'usermanagement' === activeLink,
        onLinkClick: () => {
          navigate(routePathsAdmin.HOME);
        },
      });
    }

    return links;
  }, [
    userServices,
    text.homeLink,
    text.co2Impact,
    text.userManagement,
    activeLink,
    userHasSwedishpartner,
    profile,
    userIsAdminForAnyPartner,
    serviceNames,
    navigate,
  ]);

  const buttonIcon = showMenu ? (
    <MenuButtonWrapper>
      <Close color={colors.base.white} width={spacing.small} height={spacing.small} />
    </MenuButtonWrapper>
  ) : (
    <MenuButtonWrapper>
      <Menu color={colors.base.white} width={spacing.small} height={spacing.small} />
    </MenuButtonWrapper>
  );

  return (
    <MobileMenuWrapper>
      <MenuButton
        onClick={() => {
          toggleMenu();
        }}
      >
        {buttonIcon}
      </MenuButton>
      {showMenu && (
        <MobileMenuFoldout>
          <MobileMenuFoldoutList>
            {menuLinks.map((menuLink) => (
              <MobileMenuFoldoutListItem key={menuLink.linkText} data-testid={menuLink.linkText}>
                <MobileMenuLink
                  isActive={menuLink.active}
                  href={menuLink.link}
                  onClick={(e) => {
                    e.preventDefault();
                    menuLink.onLinkClick();
                    toggleMenu();
                  }}
                >
                  {menuLink.linkText}
                </MobileMenuLink>
              </MobileMenuFoldoutListItem>
            ))}
          </MobileMenuFoldoutList>
        </MobileMenuFoldout>
      )}
    </MobileMenuWrapper>
  );
};
