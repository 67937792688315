import { type PropsWithChildren } from 'react';
import { LocationInfoWrapper, StyledGroupedListContent, StyledLabel } from './style';

type DisplayLocationProps = PropsWithChildren<{
  label: string;
  showBold: boolean;
  inactive: boolean;
}>;

export const DisplayLocation = ({
  label,
  children,
  showBold,

  inactive,
}: DisplayLocationProps) => {
  return (
    <LocationInfoWrapper>
      <StyledGroupedListContent>
        <StyledLabel showBold={showBold} inactive={inactive}>
          {label}
        </StyledLabel>
        {children}
      </StyledGroupedListContent>
    </LocationInfoWrapper>
  );
};
