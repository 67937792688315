import { type PartnerResItem, type PartnerTreeNodeV2 } from '@stenarecycling/customer-portal-types';
import { isMergedItem, LocationCountTemplate } from './utils';

export const getNameAndTextForFnoPartner = (
  partner: PartnerTreeNodeV2,
  level: number,
  parentName?: string,
) => {
  const _isMergedItem = isMergedItem(partner);

  return {
    nameAndText: getNameAndText(partner, level, _isMergedItem, parentName),
    addressAndId: getAddressAndId(partner, _isMergedItem),
  };
};

const getNameAndText = (
  partner: PartnerTreeNodeV2,
  level: number,
  _isMergedItem: boolean,
  parentName?: string,
) => {
  let nameAndText = '';

  if (level === 0) {
    nameAndText = partner.item.name.trim();
    nameAndText = appendDescription(nameAndText, _isMergedItem, partner);
  }

  if (level > 0) {
    if (parentName?.trim() !== partner.item.name.trim()) {
      nameAndText = partner.item.name.trim();

      nameAndText = appendDescription(nameAndText, _isMergedItem, partner);
    } else {
      nameAndText = (partner.item.description || partner.item.name).trim();

      if (_isMergedItem) {
        const childDescription = getChildDescription(partner);

        if (childDescription) {
          nameAndText = childDescription.trim();
        }
      }
    }
  }

  return nameAndText;
};

const appendDescription = (
  nameAndText: string,
  _isMergedItem: boolean,
  partner: PartnerTreeNodeV2,
) => {
  if (!_isMergedItem) {
    const desc = partner.item.description;

    if (desc && desc.trim() !== partner.item.name.trim()) {
      nameAndText += `, ${partner.item.description.trim()}`;
    }

    return nameAndText;
  }

  return appendChildDescription(nameAndText, partner);
};

const appendChildDescription = (nameAndText: string, partner: PartnerTreeNodeV2) => {
  const childDescription = getChildDescription(partner);

  if (childDescription) {
    nameAndText += `, ${childDescription}`;
  }

  return nameAndText;
};

const getAddressAndId = (partner: PartnerTreeNodeV2, _isMergedItem: boolean) => {
  let addressAndId = '';
  const isAddress = partner.item.type === 'DELIVERYADRESS';

  if (isAddress) {
    addressAndId = `${getAddrFromItem(partner.item)} (${getParentBPId(partner.item.dwKey)})`;
  } else if (_isMergedItem) {
    const firstDeliveryAddress = partner.children.find(
      (c) => c.item.type === 'DELIVERYADRESS',
    )?.item;

    addressAndId = `${getAddrFromItem(firstDeliveryAddress)} (${partner.item.id})`;
  } else {
    addressAndId = `${LocationCountTemplate} (${partner.item.id})`;
  }

  return addressAndId;
};

const getAddrFromItem = (item?: PartnerResItem) => {
  if (!item) {
    return '';
  }

  let desc = '';

  desc += item.street;
  desc += item.street ? ', ' : '';
  desc += item.city;

  return desc;
};

const getParentBPId = (partnerId: string) => {
  const [, bpId] = partnerId.split('_');

  return bpId;
};

const getChildDescription = (partner: PartnerTreeNodeV2) => {
  const deliveryAddress = partner.children.find(
    (child) => child.item.type === 'DELIVERYADRESS',
  )?.item;

  return deliveryAddress?.description;
};
