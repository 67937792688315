import {
  type StableBatteryAssessment,
  type BatteryAssessment,
  type CriticalBatteryAssessment,
} from '@stenarecycling/customer-portal-types';

export const getBatteryTypeKey = (input: BatteryAssessment['type']) => {
  switch (input) {
    case 'cells':
      return 'request.battery.pickup.form.battery-type.cells';
    case 'module':
      return 'request.battery.pickup.form.battery-type.modules';
    case 'pack':
      return 'request.battery.pickup.form.battery-type.battery-pack';

    default:
      return '';
  }
};
export const getBatteryApplicationFieldKey = (input: BatteryAssessment['applicationField']) => {
  switch (input) {
    case 'ev':
      return 'request.battery.pickup.form.battery-application-field.ev';
    case 'lmt':
      return 'request.battery.pickup.form.battery-application-field.lmt';
    case 'sli':
      return 'request.battery.pickup.form.battery-application-field.sli';
    case 'sbess':
      return 'request.battery.pickup.form.battery-application-field.sbess';
    case 'portable':
      return 'request.battery.pickup.form.battery-application-field.portable';
    case 'dont_know':
      return 'request.battery.pickup.form.battery-application-field.dont_know';
    default:
      return '';
  }
};

export const getBatteryConditionKey = (input: BatteryAssessment['condition']) => {
  switch (input) {
    case 'stable':
      return 'battery.pickup.step3.summary.condition.stable';
    case 'damaged':
      return 'battery.pickup.step3.summary.condition.damaged';
    case 'critical':
      return 'battery.pickup.step3.summary.condition.critical';
    default:
      return '';
  }
};

export const getBatteryYesNoKey = (input: StableBatteryAssessment['sturdyCasing']) => {
  switch (input) {
    case true:
      return 'request.battery.pickup.yes';
    case false:
      return 'request.battery.pickup.no';
    default:
      return '';
  }
};

export const getBatteryHavePackagingKey = (input: StableBatteryAssessment['sturdyCasing']) => {
  switch (input) {
    case true:
      return 'request.battery.pickup.yes';
    case false:
      return 'request.battery.pickup.fields.have_packaging.no';
    default:
      return '';
  }
};
export const getBatteryPackagingKey = (input: BatteryAssessment['packaging']) => {
  switch (input) {
    case 'stable_sturdy_outer_packaging':
      return 'battery.pickup.recommended-packaging.name.stable_sturdy_outer_packaging';
    case 'stable_original_box_pallet':
      return 'battery.pickup.recommended-packaging.name.stable_original_box_pallet';
    case 'stable_y_certified':
      return 'battery.pickup.recommended-packaging.name.stable_y_certified';

    case 'damaged_more_than_30kg':
      return 'battery.pickup.recommended-packaging.name.damaged_more_than_30kg';
    case 'damaged_less_than_30kg':
      return 'battery.pickup.recommended-packaging.name.damaged_less_than_30kg';
    case 'critical':
      return 'battery.pickup.recommended-packaging.name.critical';

    default:
      return '';
  }
};

export const getBatteryFormFactor = (input: CriticalBatteryAssessment['formFactor']) => {
  switch (input) {
    case 'cylindrical':
      return 'battery.pickup.critical.form-factor.cylindrical';
    case 'prismatic':
      return 'battery.pickup.critical.form-factor.prismatic';
    case 'pouch':
      return 'battery.pickup.critical.form-factor.pouch';
    case 'unknown':
      return 'battery.pickup.critical.form-factor.unknown';
    default:
      return '';
  }
};

export const getBatteryCellChemistry = (input: CriticalBatteryAssessment['cellChemistry']) => {
  switch (input) {
    case 'nmc 6-2-2':
      return 'battery.pickup.critical.cell-chemistry.nmc-6-2-2';
    case 'nmc 8-1-1':
      return 'battery.pickup.critical.cell-chemistry.nmc-8-1-1';
    case 'nmc 9-0.5-0.5':
      return 'battery.pickup.critical.cell-chemistry.nmc-9-0.5-0.5';
    case 'nmc (other/unspecified)':
      return 'battery.pickup.critical.cell-chemistry.nmc-other-unspecified';
    case 'nca':
      return 'battery.pickup.critical.cell-chemistry.nca';
    case 'lco':
      return 'battery.pickup.critical.cell-chemistry.lco';
    case 'lfp':
      return 'battery.pickup.critical.cell-chemistry.lfp';
    case 'lto (anode chemistry)':
      return 'battery.pickup.critical.cell-chemistry.lto-anode-chemistry';
    case 'unknown':
      return 'battery.pickup.critical.cell-chemistry.unknown';
    default:
      return '';
  }
};

export const getBatteryPackagingTextKey = (input: BatteryAssessment['packaging'], v2?: boolean) => {
  if (v2) {
    switch (input) {
      case 'stable_sturdy_outer_packaging':
        return 'battery.pickup.recommended-packaging.v2.text.stable_sturdy_outer_packaging';
      case 'stable_original_box_pallet':
        return 'battery.pickup.recommended-packaging.v2.text.stable_original_box_pallet';
      case 'stable_y_certified':
        return 'battery.pickup.recommended-packaging.v2.text.stable_y_certified';

      case 'damaged_more_than_30kg':
        return 'battery.pickup.recommended-packaging.v2.text.damaged_more_than_30kg';
      case 'damaged_less_than_30kg':
        return 'battery.pickup.recommended-packaging.v2.text.damaged_less_than_30kg';
      case 'critical':
        return 'battery.pickup.recommended-packaging.v2.text.critical';

      default:
        return '';
    }
  }

  switch (input) {
    case 'stable_sturdy_outer_packaging':
      return 'battery.pickup.recommended-packaging.text.stable_sturdy_outer_packaging';
    case 'stable_original_box_pallet':
      return 'battery.pickup.recommended-packaging.text.stable_original_box_pallet';
    case 'stable_y_certified':
      return 'battery.pickup.recommended-packaging.text.stable_y_certified';

    case 'damaged_more_than_30kg':
      return 'battery.pickup.recommended-packaging.text.damaged_more_than_30kg';
    case 'damaged_less_than_30kg':
      return 'battery.pickup.recommended-packaging.text.damaged_less_than_30kg';
    case 'critical':
      return 'battery.pickup.recommended-packaging.text.critical';
    default:
      return '';
  }
};
