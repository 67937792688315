import {
  type CO2ByArticleGroup,
  type CO2Trend,
  type CostByArticleGroupOther,
  type FinancialsTrend,
  type RevenueCostsByArticleGroup,
  type TotalVolumeTrend,
  type TransportTrend,
  type VolumeByArticle,
  type VolumeByArticleGroup,
  type VolumeTrend,
  type WasteHierarchy,
  type WasteHierarchyRandD,
} from './data';
import type { ChartOptions, ChartType } from 'chart.js';

export type statisticsDataTypes =
  | WasteHierarchy
  | WasteHierarchyRandD
  | CostByArticleGroupOther[]
  | CO2Trend[]
  | VolumeTrend[]
  | TransportTrend[]
  | CO2ByArticleGroup[]
  | VolumeByArticleGroup[]
  | TotalVolumeTrend[]
  | VolumeByArticle[]
  | RevenueCostsByArticleGroup[]
  | FinancialsTrend[];

export type dataFieldObj = Record<string, unknown>[];

export type TranslateFunc = (key: string) => string;

export type GraphTableDataRowI = { value: string; tooltipText?: string }[];

export type GraphTableDataI = {
  label: string;
  title?: string;
  rows: GraphTableDataRowI[];
  columns: string[];
};

export type TagSelectionProps = {
  date?: { dateFrom: string; dateTo: string };
  tags: {
    label: string;
    circleColor: string;
  }[];
};

export type $SelectedPickupPoints = string[];
export type $DateRangeType = [string, string];
export type $SelectedArticles = string[];

export type DownloadFileRes = {
  link: string;
};

export type GraphDataFromApiVariables = {
  category: AnalysisContextCategoryTypes;
  graphType: graphTypesI;
  subGraphType?: FinancialSubGraphTypesI;
  dateSpan: $DateRangeType;
  articles: $SelectedArticles;
  metadata?: {
    classifications: string[];
    references: string[];
    requisitions: string[];
  };
  businessPartners: $SelectedPickupPoints;
  accessToken: string;
  transactionId: string;
  forceHideFinance?: boolean;
};

export enum GraphComparingTypesI {
  PERIOD = 'PERIOD',
  BUSINESS_PARTNERS = 'BUSINESS_PARTNERS',
}

export type GraphDataResponseI = {
  dataSet: GraphDataInputI;
  data: statisticsDataTypes[];
  tags: TagSelectionProps[];
  table: GraphTableDataI[];
};

export enum AnalysisContextCategoryTypes {
  VOLUME = 'volume',
  ENVIRONMENT = 'environment',
  FINANCIAL = 'financial',
}

export enum VolumeGraphTypesI {
  ARTICLE_GROUPS = 'article_groups',
  ARTICLES = 'articles',
  VOLUME_TRENDS = 'volume_trends',
}

export enum EnvironmentGraphTypesI {
  C02_SAVINGS = 'c02_savings',
  EMISSION_TREND = 'emission_trend',
  WASTE_HIERARCHY = 'waste_hierarchy',
  RECOVERY_DISPOSAL = 'recovery_disposal',
}

export enum FinancialAllGraphTypesI {
  OTHER_COST = 'other_cost',
  MATERIAL_COST = 'material_cost',
  REVENUE_TREND = 'revenue_trend',
  TOTAL_REVENUE = 'total_revenue',
  MATERIAL_VOLUME = 'material_volume',
  TRANSPORT_COST = 'transport_cost',
}

export enum FinancialSubGraphTypesI {
  TOTAL_REVENUE = FinancialAllGraphTypesI.TOTAL_REVENUE,
  MATERIAL_VOLUME = FinancialAllGraphTypesI.MATERIAL_VOLUME,
  TRANSPORT_COST = FinancialAllGraphTypesI.TRANSPORT_COST,
}

export enum FinancialGraphTypesI {
  REVENUE_TREND = FinancialAllGraphTypesI.REVENUE_TREND,
  MATERIAL_COST = FinancialAllGraphTypesI.MATERIAL_COST,
  OTHER_COST = FinancialAllGraphTypesI.OTHER_COST,
}

export type graphTypesI =
  | VolumeGraphTypesI
  | EnvironmentGraphTypesI
  | FinancialSubGraphTypesI
  | FinancialGraphTypesI
  | FinancialAllGraphTypesI;

export type GraphDataInputI = {
  datasets: GraphDataset[];
  labels: string[];
  chartType: ChartType;
  tooltipLabels: ToolTipsI[][];
  options?: Partial<ChartOptions>;
};

export type GraphDataset = {
  data: ((number | string) | { x: number | string; y: number | string } | unknown)[];
  label?: string;
  options?: Partial<ChartOptions>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export type ToolTipsI = {
  title: string;
  datasetTitle?: {
    circleColor: string;
    title: string;
  };
  labels: {
    title?: string;
    text: string;
    rawValue: number;
    additionalText?: string;
  }[];
};
