import { Country, type PartnerTreeNodeV2 } from '@stenarecycling/customer-portal-types';

import { getPartnerDisplay } from '@stenarecycling/customer-portal-utils';
import { type LocationListItemsType } from '../DropdownV2/components/DropdownList/Lists/LocationPicker/types';
import { getNameAndTextForFnoPartner } from './fnoNameUtil';
import { getFullLabelForFire, getNameAndTextForFirePartner } from './fireNameUtil';

export const LocationCountTemplate = '#COUNT';

type GetDropdownItemsProps = {
  partners: PartnerTreeNodeV2[];
  shouldExcludeInactiveValues: boolean;
  level: number;
  allowSelectingBusinessPartners: boolean;
  hideNBPPrefix?: boolean;
  parentName?: string;
  newLookForFire?: boolean;
};

export const partnerTreesToListDropdown = ({
  partners,
  shouldExcludeInactiveValues,
  level,
  allowSelectingBusinessPartners,
  hideNBPPrefix,
  parentName,
  newLookForFire,
}: GetDropdownItemsProps): LocationListItemsType[] => {
  const items = partners.toSorted(sortPartners).map((partner) => {
    if (hideNBPPrefix) {
      partner.item.name = partner.item.name.replace('(Ej Syd)', '').replace('(Syd)', '').trim();
      partner.item.description = partner.item.description
        .replace('(Ej Syd)', '')
        .replace('(Syd)', '')
        .trim();
    }

    const _isMergedItem = isMergedItem(partner);
    const _isSamlingsMotpart = isSamlingsMotpart(partner, newLookForFire);

    const { nameAndText, addressAndId } = makePartnerLabels(
      partner,
      level,
      newLookForFire,
      parentName,
    );
    const isActive = getIsPartnerActive(partner);
    const isUnselectable =
      getIsPartnerUnselectable(partner, allowSelectingBusinessPartners) ||
      (!isActive && shouldExcludeInactiveValues);

    const firstChild = partner.children[0];
    const dwKey = _isMergedItem ? firstChild.item.dwKey : partner.item.dwKey;
    const childCount = partner.item.childCount;
    const locationCount = partner.item.locationCount;
    const isLocation = getIsLocation(partner, _isMergedItem);

    let type: 'group' | 'value' = isUnselectable ? 'group' : 'value';

    if (_isMergedItem) {
      type = 'value';
    }

    const fullLabel = getFullLabel(partner, newLookForFire);

    const showChildren = (_isMergedItem && _isSamlingsMotpart) || !_isMergedItem;
    let children = [...partner.children];

    if (_isSamlingsMotpart) {
      children = children.filter(
        (child) => child.item.type === 'BUSINESSPARTNER' || child.item.type === 'BusinessPartner',
      );
    }

    return {
      value: dwKey,
      label: nameAndText,
      altLabel: fullLabel,
      meta: {
        description: addressAndId,
        childCount: childCount ?? 0,
        locationCount: locationCount,
        isLocation: !!isLocation,
        isActive,
        shouldExcludeInactiveValues,
        isSamlingsMotpart: _isSamlingsMotpart,
        isUnselectable,
        activeChildren: !!partner.item.hasActiveChildren,
        source: partner.item.source,
      },
      type,
      children: showChildren
        ? partnerTreesToListDropdown({
            partners: children,
            shouldExcludeInactiveValues,
            level: level + 1,
            allowSelectingBusinessPartners,
            hideNBPPrefix,
            parentName: partner.item.name,
            newLookForFire,
          })
        : [],
    } satisfies LocationListItemsType;
  });

  return items;
};

const isSamlingsMotpart = (partner: PartnerTreeNodeV2, newLookForFire?: boolean) => {
  if (newLookForFire && partner.item.source === 'FIRE') {
    return partner.children.length > 0;
  }
  const isMergeItem = isMergedItem(partner);
  const bpChildren = partner.children.filter((child) => child.item.type === 'BUSINESSPARTNER');

  return isMergeItem && bpChildren.length > 0;
};

const getIsLocation = (partner: PartnerTreeNodeV2, isMerged: boolean) => {
  if (isMerged) {
    return true;
  }

  if (partner.item.source === 'CS') {
    return partner.item.isLocation && !partner.children.length;
  }

  return partner.item.isLocation;
};

export const isMergedItem = (partner: PartnerTreeNodeV2) => {
  const isAddress = partner.item.type === 'DELIVERYADRESS';

  const deliveryAddresses = partner.children.filter(
    (child) => child.item.type === 'DELIVERYADRESS',
  );

  const isMerged =
    shouldUseNewPickerForSource(partner.item.source) &&
    !isAddress &&
    deliveryAddresses.length === 1;

  return isMerged;
};

const getFullLabel = (partner: PartnerTreeNodeV2, newLookForFire?: boolean) => {
  if (partner.item.source === 'FIRE' && newLookForFire) {
    return getFullLabelForFire(partner);
  }

  if (!shouldUseNewPickerForSource(partner.item.source)) {
    return;
  }

  const _isMergeItem = isMergedItem(partner);

  if (_isMergeItem) {
    let label = partner.item.name.trim();

    const deliveryAddress = partner.children.find(
      (child) => child.item.type === 'DELIVERYADRESS',
    )?.item;

    const childDescription = deliveryAddress?.description.trim();

    if (childDescription) {
      label += `, ${childDescription}`;
    }

    return label;
  }

  const display = getPartnerDisplay(partner.item);

  return display.nameAndText;
};

const getIsPartnerUnselectable = (
  partner: PartnerTreeNodeV2,
  allowSelectingBusinessPartners: boolean,
) => {
  if (allowSelectingBusinessPartners) return false;
  if (shouldUseNewPickerForSource(partner.item.source)) {
    return !getIsLocation(partner, isMergedItem(partner));
  }

  return false;
};

const makePartnerLabels = (
  partner: PartnerTreeNodeV2,
  level: number,
  newLookForFire?: boolean,
  parentName?: string,
) => {
  if (shouldUseNewPickerForSource(partner.item.source)) {
    return getNameAndTextForFnoPartner(partner, level, parentName);
  } else if (newLookForFire && partner.item.source === 'FIRE') {
    return getNameAndTextForFirePartner(partner, level, parentName);
  }

  return getPartnerDisplay(partner.item);
};

export const shouldUseNewPickerForSource = (source?: string) => {
  return source === 'FNO' || source === 'CS';
};

export const getIsPartnerActive = (partner: PartnerTreeNodeV2) => {
  const _isMergedItem = isMergedItem(partner);

  if (_isMergedItem) {
    const deliveryAddress = partner.children.find((child) => child.item.type === 'DELIVERYADRESS');

    return !!deliveryAddress?.item.isActive;
  }

  if (partner.item.companyId === Country.SWEDEN) {
    return partner.item.isActive;
  }

  return partner.item.isActivePickupPlace;
};

export const hasInactiveChildren = (partners: PartnerTreeNodeV2[]): boolean => {
  return partners.some((partner) => {
    const isActive = getIsPartnerActive(partner);

    if (!isActive) {
      return true;
    }

    return hasInactiveChildren(partner.children);
  });
};

const sortPartners = (a: PartnerTreeNodeV2, b: PartnerTreeNodeV2) => {
  if (!shouldUseNewPickerForSource(a.item.source) || !shouldUseNewPickerForSource(b.item.source)) {
    const textA = getPartnerDisplay(a.item).nameAndText;
    const textB = getPartnerDisplay(b.item).nameAndText;

    return textA.localeCompare(textB);
  }

  //Sort DeliveryAdress first
  if (a.item.type === 'DELIVERYADRESS' && b.item.type !== 'DELIVERYADRESS') {
    return -1;
  }

  if (a.item.type !== 'DELIVERYADRESS' && b.item.type === 'DELIVERYADRESS') {
    return 1;
  }

  //Sort merged item next

  const aIsMergedItem = isMergedItem(a);
  const bIsMergedItem = isMergedItem(b);

  if (aIsMergedItem && !bIsMergedItem) {
    return -1;
  }

  if (!aIsMergedItem && bIsMergedItem) {
    return 1;
  }

  //Sort by name
  return a.item.name.localeCompare(b.item.name);
};
