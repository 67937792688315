/* eslint-disable @typescript-eslint/unbound-method */
import { type ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { DatePickerHeader, DatePickerMonthLabel } from '../../styles';
import { ArrowRight, ArrowLeft } from '../../../../../Icons';
import { IconButton } from '../../../../IconButton';

export type CustomHeaderDatepickerProps = ReactDatePickerCustomHeaderProps & {
  locale: string;
};

const DatepickerCustomHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  locale,
}: CustomHeaderDatepickerProps) => (
  <DatePickerHeader>
    <IconButton variant="secondary" disabled={prevMonthButtonDisabled} onClick={decreaseMonth}>
      <ArrowLeft />
    </IconButton>

    <DatePickerMonthLabel>
      {date.toLocaleDateString(locale, { month: 'long', year: 'numeric' })}
    </DatePickerMonthLabel>

    <IconButton variant="secondary" disabled={nextMonthButtonDisabled} onClick={increaseMonth}>
      <ArrowRight />
    </IconButton>
  </DatePickerHeader>
);

export default DatepickerCustomHeader;
