import { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import { co2ImpactroutePaths } from 'core/lib/routePaths/co2ImpactRoutePaths';
import { RouteLoader } from '#components/Loader/RouteLoader';

const Co2Impact = lazy(() => import('co2_impact/central-routes/react-routes'));

const Co2ImpactOverview = lazy(() =>
  import('co2_impact/central-routes/react-routes').then((module) => ({
    default: module.Co2ImpactOverview,
  })),
);

const Co2ImpactEmissions = lazy(() =>
  import('co2_impact/central-routes/react-routes').then((module) => ({
    default: module.Co2ImpactEmissions,
  })),
);

const Co2ImpactAbout = lazy(() =>
  import('co2_impact/central-routes/react-routes').then((module) => ({
    default: module.Co2ImpactAbout,
  })),
);

const Co2ImpactPricing = lazy(() =>
  import('co2_impact/central-routes/react-routes').then((module) => ({
    default: module.Co2ImpactPricing,
  })),
);

const Co2ImpactCheckout = lazy(() =>
  import('co2_impact/central-routes/react-routes').then((module) => ({
    default: module.Co2ImpactCheckout,
  })),
);

const Co2ImpactConfirmation = lazy(() =>
  import('co2_impact/central-routes/react-routes').then((module) => ({
    default: module.Co2ImpactConfirmation,
  })),
);

export const CO2ImpactRouteTree = (
  <Route
    path={co2ImpactroutePaths.HOME}
    element={
      <Suspense fallback={<RouteLoader />}>
        <Co2Impact />
      </Suspense>
    }
  >
    <Route index element={<Co2ImpactOverview />} />
    <Route path={co2ImpactroutePaths.EMISSIONS} element={<Co2ImpactEmissions />}>
      <Route index element={<Co2ImpactEmissions />} />
      <Route path={co2ImpactroutePaths.EMISSIONS_PARAMS} element={<Co2ImpactEmissions />} />
    </Route>
    <Route path={co2ImpactroutePaths.ABOUT} element={<Co2ImpactAbout />}>
      <>
        <Route path={co2ImpactroutePaths.ABOUT_METHOD} element={<Co2ImpactAbout />} />
        <Route path={co2ImpactroutePaths.ABOUT_FOOTPRINTS} element={<Co2ImpactAbout />} />
        <Route path={co2ImpactroutePaths.ABOUT_BENEFITS} element={<Co2ImpactAbout />} />
        <Route path={co2ImpactroutePaths.ABOUT_STANDARDS} element={<Co2ImpactAbout />} />
        <Route path={co2ImpactroutePaths.ABOUT_GET_STARTED} element={<Co2ImpactAbout />} />
      </>
    </Route>
    <Route path={co2ImpactroutePaths.PRICING} element={<Co2ImpactPricing />} />
    <Route path={co2ImpactroutePaths.CHECKOUT} element={<Co2ImpactCheckout />} />
    <Route path={co2ImpactroutePaths.CHECKOUT_CONFIRMATION} element={<Co2ImpactConfirmation />} />
  </Route>
);

export const preloadCO2Impact = () => {
  // Import default export to trigger preload
  void import('co2_impact/central-routes/react-routes');
};
