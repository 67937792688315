import {
  type CostByArticleGroupOther,
  type FinancialsTrend,
  type GraphTableDataI,
  type RevenueCostsByArticleGroup,
  type TransportTrend,
} from '@stenarecycling/customer-portal-types';
import { formatNumberToCurrency } from '../../utils';

export const makeTableDataFinanceOtherCost = (
  data: CostByArticleGroupOther[],
  label: string,
  t: (key: string) => string,
): GraphTableDataI => {
  const currencyCode = data[0]?.currencyCode ?? '';
  const columns = [
    t('analysisPage.table.articlegroup'),
    t('analysisPage.table.income'),
    t('analysisPage.table.cost'),
    t('analysisPage.table.sum'),
  ];

  const rows = data.map((item) => [
    { value: item.articleGroupDescription },
    {
      value: formatNumberToCurrency(item.revenueAmount, currencyCode),
    },
    {
      value: formatNumberToCurrency(item.costAmount, currencyCode),
    },
    {
      value: formatNumberToCurrency(item.costAmount + item.revenueAmount, currencyCode),
    },
  ]);

  return {
    columns,
    rows,
    label,
  };
};

export const makeTableDataFinanceMaterialCost = (
  data: RevenueCostsByArticleGroup[],
  label: string,
  t: (key: string) => string,
): GraphTableDataI => {
  const currencyCode = data[0]?.currencyCode ?? '';
  const columns = [
    t('analysisPage.table.articlegroup'),
    t('analysisPage.table.income'),
    t('analysisPage.table.cost'),
    t('analysisPage.table.sum'),
  ];
  const rows = data.map((item) => [
    { value: item.articleGroupDescription },
    {
      value: formatNumberToCurrency(item.revenueAmount, currencyCode),
    },
    {
      value: formatNumberToCurrency(item.costAmount, currencyCode),
    },
    {
      value: formatNumberToCurrency(item.costAmount + item.revenueAmount, currencyCode),
    },
  ]);

  return {
    columns,
    rows,
    label,
  };
};

export const makeTableDataFinanceRevenueTrend = (
  data: FinancialsTrend[],
  label: string,
  t: (key: string) => string,
): GraphTableDataI => {
  const currencyCode = data[0]?.currencyCode ?? '';
  const columns = [
    t('analysisPage.table.month'),
    t('analysisPage.table.income'),
    t('analysisPage.table.cost'),
    t('analysisPage.table.sum'),
  ];
  const rows = data.map((item) => [
    { value: item.shipmentMonth },
    {
      value: formatNumberToCurrency(item.revenueAmount, currencyCode),
    },
    {
      value: formatNumberToCurrency(item.costAmount, currencyCode),
    },
    {
      value: formatNumberToCurrency(item.costAmount + item.revenueAmount, currencyCode),
    },
  ]);

  return {
    columns,
    rows,
    label,
  };
};

export const makeTableDataFinanceRevenueTrendTransportCost = (
  data: TransportTrend[],
  label: string,
  t: (key: string) => string,
): GraphTableDataI => {
  const currencyCode = data[0]?.currencyCode ?? '';
  const columns = [t('analysisPage.table.month'), t('analysisPage.table.cost')];
  const rows = data.map((item) => [
    { value: item.shipmentMonth },
    {
      value: formatNumberToCurrency(item.transportAmount, currencyCode),
    },
  ]);

  return {
    columns,
    rows,
    label,
  };
};
