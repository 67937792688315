// Recursively find partner by dwKey
import { type PartnerTreeNodeV2 } from '@stenarecycling/customer-portal-types';

export const findPartnerByDwKey = (
  dwKey: string,
  partners: PartnerTreeNodeV2[] = [],
): PartnerTreeNodeV2 | undefined => {
  for (const partner of partners) {
    if (partner.item.dwKey === dwKey) {
      return partner;
    }
    if (partner.children) {
      const foundPartner = findPartnerByDwKey(dwKey, partner.children);

      if (foundPartner) {
        return foundPartner;
      }
    }
  }

  return;
};
