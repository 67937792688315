import { type Country, type PartnerResponse } from '..';

export type SubscriptionType = 'CO2';

export type SubscriptionStatus = 'new' | 'active' | 'cancelled' | 'inactive';

export type SubscriptionUser = {
  firstName: string;
  lastName: string;
  email: string;
  id: string;
  partners: PartnerResponse[];
};

export type Subscription = {
  id: string;
  type: SubscriptionType;
  status: SubscriptionStatus;
  endDate?: string | null;
  createdDate: string;
  modifiedDate: string;
  userId: string;
  locationRootId: string;
  invoicePartnerId: string;
  trialEndDateTime: string;
};

type InvoiceRecipient = {
  name: string;
  city: string;
  street: string;
  postalCode: string;
  id: string;
};

export type SubscriptionResponse = Subscription & {
  locationRootPartner: PartnerResponse;
  invoiceRecipient: InvoiceRecipient;
};

export type SubscriptionOrder = {
  userId: string;
  type: SubscriptionType;
  invoicePartnerId: string;
  locationRootId: string;
  termsOfPurchase: boolean;
  generalTerms: boolean;
  privacyPolicy: boolean;
  email: string;
  market: Country;
};

export type SubscriptionOrderRow = {
  id: string;
  buyer: string;
  buyerId?: string;
  createdDate: string;
  modifiedDate: string;
  price: number;
  currency: string;
  trialEndDateTime: string;
  endDate?: string | null;
  status: SubscriptionStatus;
  cancellationReason?: string | null;
} & SubscriptionOrder;

export const CancellationReason = {
  CANCELLED_BY_ADMIN: 'Cancelled by Admin',
  CANCELLED_BY_USER: 'Cancelled by User',
  USER_DELETED: 'User was deleted',
} as const;
