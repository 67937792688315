import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { type PartnerTreeNodeV2 } from '@stenarecycling/customer-portal-types';
import { type DropdownProviderProps, DropdownV2 } from '../DropdownV2';
import { locationSearchItems } from '../DropdownV2/components/DropdownList/Lists/LocationPicker/utils';
import { LocationPickerSearch } from '../DropdownV2/components/DropdownList/Lists/LocationPicker/components/LocationPickerSearch/DropdownSearch';
import { LocationPickerList } from '../DropdownV2/components/DropdownList/Lists/LocationPicker/LocationPickerList';
import { type LocationListItemsType } from '../DropdownV2/components/DropdownList/Lists/LocationPicker/types';
import { partnerTreesToListDropdown } from './utils';
import {
  InactiveStateProvider,
  useInactiveState,
} from './hooks/useLocationPicker/useInactiveState';

type LocationPickerPickedProps = Pick<
  DropdownProviderProps,
  | 'required'
  | 'selectedValues'
  | 'loading'
  | 'onChange'
  | 'errorMessage'
  | 'error'
  | 'multiSelect'
  | 'disabled'
  | 'autoSelect'
> & {
  label?: string;
};

export type LocationGroup = {
  rootPartnerId: string;
  allPartnerIds: string[];
};

export type LocationPickerProps = LocationPickerPickedProps & {
  locations: PartnerTreeNodeV2[];
  hideInactiveLocations: boolean;
  showInactiveToggle?: boolean;
  onInactiveToggle?: (hideInactive: boolean) => void;
  allowSelectingBusinessPartners?: boolean;
  hideNBPPrefix?: boolean;
  newLookForFire?: boolean;
  onLocationGroupChange?: (groups: LocationGroup[]) => void;
};
const LocationPickerProviderLess = (props: LocationPickerProps) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const [dropdownItems, setDropdownItems] = useState<LocationListItemsType[]>([]);

  const { hideInactiveLocations } = useInactiveState();

  useEffect(() => {
    setLoading(true);

    if (props.locations.length === 0) {
      setLoading(false);

      return;
    }

    const dropdownItems = partnerTreesToListDropdown({
      partners: props.locations,
      shouldExcludeInactiveValues: hideInactiveLocations,
      level: 0,
      allowSelectingBusinessPartners: !!props.allowSelectingBusinessPartners,
      hideNBPPrefix: props.hideNBPPrefix,
      newLookForFire: props.newLookForFire,
    });

    setDropdownItems(dropdownItems);

    setLoading(false);
  }, [
    hideInactiveLocations,
    props.allowSelectingBusinessPartners,
    props.hideNBPPrefix,
    props.locations,
    props.newLookForFire,
  ]);

  return (
    <DropdownV2
      error={props.error}
      items={dropdownItems}
      disabled={props.disabled}
      loading={props.loading || loading}
      onChange={props.onChange}
      errorMessage={props.errorMessage}
      selectedValues={props.selectedValues}
      label={props.label ?? t('filter.businessPartners')}
      searchPlaceholder={t('filter.searchLocation')}
      required={props.required}
      multiSelect={props.multiSelect}
      autoSelect={props.autoSelect}
      customSearch={(inputItems, search) =>
        locationSearchItems(inputItems, search, !!props.multiSelect)
      }
      onGroupChange={(groups) => {
        if (props.onLocationGroupChange) {
          props.onLocationGroupChange(
            groups.map((group) => ({
              rootPartnerId: group.rootItemValue,
              allPartnerIds: group.allValues,
            })),
          );
        }
      }}
      placeholder={t('filter.businessPartnersPlaceholder')}
    >
      <LocationPickerSearch />

      <LocationPickerList />
    </DropdownV2>
  );
};

export const LocationPicker = (props: LocationPickerProps) => {
  const showInactiveToggle = !!props.showInactiveToggle && !!props.multiSelect;

  return (
    <InactiveStateProvider
      locations={props.locations}
      showInactiveToggle={showInactiveToggle}
      hideInactiveLocations={!!props.hideInactiveLocations}
      onInactiveToggle={props.onInactiveToggle}
    >
      <LocationPickerProviderLess {...props} />
    </InactiveStateProvider>
  );
};
