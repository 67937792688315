import { useEffect } from 'react';
import { preloadApps } from '#routes/preload';

export const Preloader = () => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      preloadApps();
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return null;
};
