import { useMemo } from 'react';
import { Button } from 'component-library';
import { LinkDropdown, type LinkDropdownItem } from './LinkDropDown';
import { MobileSubMenuWrapper, SubButtonWrapper } from './styles';
import type { SubButtonProps, SubLinkProps } from '../types';

type MobileSubMenuProps = {
  subLinks: SubLinkProps[];
  subButton?: SubButtonProps;
};
export const MobileSubMenu = ({ subLinks, subButton }: MobileSubMenuProps) => {
  const items: LinkDropdownItem[] = useMemo(() => {
    return subLinks.map((link) => ({
      value: link.href ?? '#',
      label: link.text ?? '',
      active: link.active,
      href: link.href,
      onClick: link.onClick
        ? () => {
            if (link.onClick) {
              const e: React.MouseEvent<HTMLAnchorElement> = {
                preventDefault: () => {
                  return;
                },
              } as React.MouseEvent<HTMLAnchorElement>;

              link.onClick(e);
            }
          }
        : undefined,
    }));
  }, [subLinks]);

  return (
    <MobileSubMenuWrapper>
      <LinkDropdown items={items} />
      {subButton && (
        <SubButtonWrapper>
          <Button
            fullWidth
            variant="text-primary"
            onClick={subButton.onClick}
            icon={subButton.icon}
            iconPlacement="before"
          >
            {subButton.text}
          </Button>
        </SubButtonWrapper>
      )}
    </MobileSubMenuWrapper>
  );
};
