import {
  type GraphComparingTypesI,
  type GraphDataResponseI,
  type GraphDataFromApiVariables,
  type GraphDataset,
  type PartnerTreeNodeV2,
} from '@stenarecycling/customer-portal-types';

import {
  combineCompareTags,
  combineCompareDataSets,
  combineCompareTables,
  combineCompareTooltips,
} from './combiners';

const combineCompareLabels = (labels: string[]) => {
  const uniqueLabels = new Set(labels);

  return [...uniqueLabels];
};

/**
 * Combines multiple dataSets from one source, into one dataSet.
 * @param input
 * @param compareType
 * @param sets
 * @param t
 * @param pickupPoints
 */
export const combineGraphData = (
  input: GraphDataFromApiVariables,
  compareType: GraphComparingTypesI,
  sets: GraphDataResponseI[],
  t: (key: string) => string,
  pickupPoints: PartnerTreeNodeV2[],
): GraphDataResponseI => {
  const allLabels = sets.map((set) => set.dataSet.labels).flat();
  const allDataSets = sets.map((set) => set.dataSet).flat();
  const allTags = sets.map((set) => set.tags).flat();
  const allTables = sets.map((set) => set.table).flat();
  const allData = sets.map((set) => set.data).flat();
  const { options } = sets[0].dataSet;

  const tags = combineCompareTags({
    tags: allTags,
    pickupPoints,
    input,
    compareType,
  });

  const datasets: GraphDataset[] = combineCompareDataSets({
    dataSets: allDataSets,
  });
  const tables = combineCompareTables({ tables: allTables, input, t });
  const labels = combineCompareLabels(allLabels);
  const toolTips = combineCompareTooltips({
    sets,
    input,
    compareType,
    pickupPoints,
    tags,
  });

  return {
    data: allData,
    dataSet: {
      tooltipLabels: toolTips,
      labels,
      datasets,
      options,
      chartType: 'bar',
    },
    tags: tags,
    table: tables,
  };
};
