import { type GraphDataInputI, type GraphDataset } from '@stenarecycling/customer-portal-types';

export const combineCompareDataSets = ({
  dataSets,
}: {
  dataSets: GraphDataInputI[];
}): GraphDataset[] => {
  return dataSets
    .map((graphData) => {
      return graphData.datasets.map((dataSet) => {
        // Add x-axis labels to all datasets, this is needed if the datasets have different lengths of labels.
        const data = dataSet.data.map((dataPoint, datapointIndex) => {
          return {
            x: graphData.labels[datapointIndex],
            y: dataPoint,
          };
        });

        return {
          ...dataSet,
          data,
        };
      });
    })
    .flat();
};
