import { Country, CountryEnum, type CountryEnumType } from '@stenarecycling/customer-portal-types';

export const getCountryEnumFromCompanyId = (companyId: number | string): CountryEnumType => {
  const companyIdNumber = Number(companyId);

  switch (companyIdNumber) {
    case 104:
    case 900:
      return CountryEnum.SE;
    case 250:
    case 901:
      return CountryEnum.NO;
    case 555:
    case 903:
      return CountryEnum.DK;
    case 505:
      return CountryEnum.FI;
    case 105:
      return CountryEnum.PL;
    default:
      return CountryEnum.SE;
  }
};

export const getCountryFromCompanyId = (companyId: number | string): Country => {
  const companyIdNumber = Number(companyId);

  switch (companyIdNumber) {
    case 104:
    case 900:
      return Country.SWEDEN;
    case 250:
    case 901:
      return Country.NORWAY;
    case 555:
    case 903:
      return Country.DENMARK;
    case 505:
      return Country.FINLAND;
    case 105:
      return Country.POLAND;
    default:
      return Country.SWEDEN;
  }
};

export const COMPANY_IDS: number[] = [104, 900, 250, 901, 903, 555, 505, 105];

export const getCompanyIdsFromCountry = (country: CountryEnumType): number[] => {
  const seIds = [104, 900];
  const noIds = [250, 901];
  const dkIds = [555, 903];
  const fiIds = [505];
  const plIds = [105];

  switch (country) {
    case CountryEnum.SE:
      return seIds;
    case CountryEnum.NO:
      return noIds;
    case CountryEnum.DK:
      return dkIds;
    case CountryEnum.FI:
      return fiIds;
    case CountryEnum.PL:
      return plIds;
    default:
      return seIds;
  }
};

export const isDwkeyCS = (dwkey: string): boolean => {
  const companyId = getCompanyIdFromDwkey(dwkey);

  return companyId === 900 || companyId === 901 || companyId === 903;
};

export const getCompanyIdFromDwkey = (dwkey: string): number => {
  const companyId = dwkey.split('_')[0];

  return Number(companyId);
};

export const isDwkeyInCountry = (dwkey: string, country: CountryEnumType): boolean => {
  const companyId = getCompanyIdFromDwkey(dwkey);
  const companyIds = getCompanyIdsFromCountry(country);

  return companyIds.includes(companyId);
};
