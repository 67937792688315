import { lazy, Suspense } from 'react';
import { Route } from 'react-router-dom';
import { routePathsPortalHome } from 'core/lib/routePaths/portalHomeRoutePaths';
import { RouteLoader } from '#components/Loader/RouteLoader';

const PortalHome = lazy(() => import('portal-home/central-routes/home-routes'));

const SupportRoute = lazy(() =>
  import('portal-home/central-routes/home-routes').then((module) => ({
    default: module.SupportRoute,
  })),
);

const Health = lazy(() =>
  import('portal-home/central-routes/home-routes').then((module) => ({
    default: module.HealthRoute,
  })),
);

export const PortalHomeRouteTree = [
  <Route
    key={0}
    path={routePathsPortalHome.HOME}
    index
    element={
      <Suspense fallback={<RouteLoader />}>
        <PortalHome />
      </Suspense>
    }
  />,
  <Route
    key={1}
    path={routePathsPortalHome.HEALTH}
    element={
      <Suspense fallback={<RouteLoader />}>
        <Health />
      </Suspense>
    }
  />,
  <Route key={2} path={routePathsPortalHome.SUPPORT}>
    <Route
      key={0}
      index
      element={
        <Suspense fallback={<RouteLoader />}>
          <SupportRoute />
        </Suspense>
      }
    />
    <Route
      key={1}
      path={routePathsPortalHome.CONTACT}
      element={
        <Suspense fallback={<RouteLoader />}>
          <SupportRoute />
        </Suspense>
      }
    />
    <Route
      key={1}
      path={routePathsPortalHome.RELEASE_NOTES}
      element={
        <Suspense fallback={<RouteLoader />}>
          <SupportRoute />
        </Suspense>
      }
    />
  </Route>,
];

export const preloadPortalHome = () => {
  //Import default export to trigger preload
  void import('portal-home/central-routes/home-routes');
};
