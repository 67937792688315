import { Button } from 'component-library';
import { type SubButtonProps, type SubLinkProps } from '../types';
import { StyledSubATag, SubMenuWrapper } from './styles';

type SubMenuProps = {
  subLinks?: SubLinkProps[];
  subButton?: SubButtonProps;
};

const SubMenu = ({ subLinks = [], subButton, ...rest }: SubMenuProps) => {
  return (
    <SubMenuWrapper {...rest}>
      <div>
        {subLinks.map((link) => (
          <StyledSubATag
            href={link.href ?? '#'}
            key={link.text}
            onClick={link.onClick}
            active={!!link.active}
            data-testid={link.text}
          >
            {link.text}
          </StyledSubATag>
        ))}
      </div>
      <div>
        {subButton && (
          <Button
            variant="text-primary"
            icon={subButton.icon}
            onClick={subButton.onClick}
            data-testid={subButton.text}
          >
            {subButton.text}
          </Button>
        )}
      </div>
    </SubMenuWrapper>
  );
};

export default SubMenu;
