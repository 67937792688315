import { createStore } from 'component-library';
import { type SubButtonProps, type SubLinkProps } from '#lib/HeaderMenu/types';

type TopMenuStoreState = {
  subLinks: SubLinkProps[];
  subButton?: SubButtonProps;
};

export const TopMenuStore = createStore<TopMenuStoreState>({
  subLinks: [],
  subButton: undefined,
});
