import { type PartnerResItem, type PartnerTreeNodeV2 } from '@stenarecycling/customer-portal-types';

export const getFullLabelForFire = (partner: PartnerTreeNodeV2) => {
  return getDescription(partner.item, true);
};

export const getNameAndTextForFirePartner = (
  partner: PartnerTreeNodeV2,
  level: number,
  parentName?: string,
) => {
  return {
    nameAndText: getNameAndText(partner, level, parentName),
    addressAndId: getAddressAndId(partner),
  };
};

const getNameAndText = (partner: PartnerTreeNodeV2, level: number, parentName?: string) => {
  let nameAndText = '';

  if (level === 0) {
    nameAndText = getDescription(partner.item, true);
  }

  if (level > 0) {
    if (parentName?.trim() !== partner.item.name.trim()) {
      nameAndText = getDescription(partner.item, true);
    } else {
      nameAndText = getDescription(partner.item, false);
    }
  }

  return nameAndText;
};

const getAddressAndId = (partner: PartnerTreeNodeV2) => {
  const addressAndId = `${getAddrFromItem(partner.item)} (${partner.item.id})`;

  return addressAndId;
};

const getAddrFromItem = (item?: PartnerResItem) => {
  if (!item) {
    return '';
  }

  let desc = '';

  desc += item.street;
  desc += item.street ? ', ' : '';
  desc += item.city;

  return desc;
};

const getDescription = (partner: PartnerResItem, includeName: boolean) => {
  const filterFunc = (item?: string) => !!item && item !== 'N/A' && item !== '.' && item.length > 0;

  const labelArr = [partner.customerText.trim()];

  if (includeName) {
    labelArr.unshift(partner.name.trim());
  }

  // If no customerText, use additionalDescription instead
  if (!filterFunc(partner.customerText)) {
    labelArr.push(partner.additionalDescription);
  }

  const label = labelArr.filter(filterFunc).join(', ');

  return label;
};
