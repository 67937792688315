import {
  GraphComparingTypesI,
  type GraphDataFromApiVariables,
  type GraphDataResponseI,
  type PartnerTreeNodeV2,
  type TagSelectionProps,
} from '@stenarecycling/customer-portal-types';
import { subtractOneYear } from '../../../../../dates';
import { findPartnerByDwKey } from '../../../../partner';
import { getPickupName } from '../../../utils';

export const combineCompareTooltips = ({
  sets,
  input,
  compareType,
  pickupPoints,
  tags,
}: {
  sets: GraphDataResponseI[];
  input: GraphDataFromApiVariables;
  compareType: GraphComparingTypesI;
  pickupPoints: PartnerTreeNodeV2[];
  tags: TagSelectionProps[];
}) => {
  if (compareType === GraphComparingTypesI.PERIOD) {
    // Order is important, first in array is previous period
    const dates = [
      [subtractOneYear(input.dateSpan[0]), subtractOneYear(input.dateSpan[1])],
      input.dateSpan,
    ];

    // Add date-description to tooltip
    return sets
      .map((set, setIndex) => {
        return set.dataSet.tooltipLabels.map((tooltips) => {
          return tooltips.map((tooltip) => ({
            datasetTitle: {
              title: dates[setIndex].join(' - '),
              circleColor: tags[setIndex]?.tags[0].circleColor,
            },
            ...tooltip,
          }));
        });
      })
      .flat();
  } else {
    // The tags are now unified so the logic to pick the color from a partner is the same for all three subContexts
    // Add bp-description to tooltip
    return sets
      .map((set, setIndex) => {
        const partner = findPartnerByDwKey(input.businessPartners[setIndex], pickupPoints);

        // const colorIndex = isFinancialCompare ? financialColorIndex : setIndex;
        return set.dataSet.tooltipLabels.map((tooltips) => {
          return tooltips.map((tooltip) => ({
            datasetTitle: {
              title: getPickupName(partner),
              circleColor: tags[0].tags[setIndex].circleColor,
            },
            ...tooltip,
          }));
        });
      })
      .flat();
  }
};
