export const graphBarColors = ['#64B5F6', '#FF9E5C', '#81C784', '#BA68C8'];
export const graphBarColorsTest = () => ['#64B5F6', '#FF9E5C', '#81C784', '#BA68C8'];

export const graphComparePeriodBarColors = ['#FF9E5C', '#64B5F6', '#81C784', '#BA68C8'];

export const graphFinancialColors = [
  '#FF9E5C',
  '#FFD5B8',
  '#64B5F6',
  '#BBDEFB',
  '#81C784',
  '#C8E6C9',
  '#BA68C8',
  '#E1BEE7',
];
export const graphFinancialColorsCompare = [
  '#64B5F6',
  '#BBDEFB',
  '#FF9E5C',
  '#FFD5B8',
  '#81C784',
  '#C8E6C9',
  '#BA68C8',
  '#E1BEE7',
];

export const graphLineColors = ['#1565C0', '#8A3800', '#2E7D32', '#6A1B9A'];
