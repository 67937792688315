import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownV2, MultiSelectList } from 'component-library';
import { useRoleIndustry } from '../hooks/useRoleIndustry/useRoleIndustry';
import { sortAlphabeticallyOtherLast } from './util';

type RoleDropdownProps = {
  roles: string[];
  setRoles: (roles: string[]) => void;
};

export const RoleDropdown = ({ roles, setRoles }: RoleDropdownProps) => {
  const { t } = useTranslation();

  const { data, isLoading } = useRoleIndustry();

  const availableRoles = useMemo(() => {
    if (!data) return [];

    return data.availableRoles
      .toSorted((a, b) => sortAlphabeticallyOtherLast(a, b, t, 'user.profile.role'))
      .map((role) => {
        const label = t(`user.profile.role.${role}`);

        return {
          label,
          value: role,
          type: 'value' as const,
          children: [],
        };
      });
  }, [data, t]);

  return (
    <DropdownV2
      maxItemsToShow={7}
      label={t('profile.field.role.title')}
      loading={isLoading}
      placeholder={t('profile.field.role.placeholder')}
      items={availableRoles}
      onChange={setRoles}
      multiSelect={true}
      selectedValues={roles}
    >
      <MultiSelectList showSelectAll={false} />
    </DropdownV2>
  );
};
