import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { DropdownV2, SimpleSelectList } from 'component-library';
import { useRoleIndustry } from '../hooks/useRoleIndustry/useRoleIndustry';
import { sortAlphabeticallyOtherLast } from './util';

type IndustryDropdownProps = {
  industry?: string;
  setIndustry: (industry: string) => void;
};

export const IndustryDropdown = ({ industry, setIndustry }: IndustryDropdownProps) => {
  const { t } = useTranslation();

  const { data, isPending } = useRoleIndustry();

  const availableIndustries = useMemo(() => {
    if (!data) return [];

    return data.availableIndustries
      .toSorted((a, b) => sortAlphabeticallyOtherLast(a, b, t, 'user.profile.industry'))
      .map((industry) => {
        const label = t(`user.profile.industry.${industry}`);

        return {
          label,
          value: industry,
          type: 'value' as const,
          children: [],
        };
      });
  }, [data, t]);

  return (
    <DropdownV2
      loading={isPending}
      maxItemsToShow={7}
      label={t('profile.field.industry.title')}
      placeholder={t('profile.field.industry.placeholder')}
      items={availableIndustries}
      onChange={(values) => {
        setIndustry(values[0]);
      }}
      selectedValues={industry ? [industry] : []}
    >
      <SimpleSelectList />
    </DropdownV2>
  );
};
