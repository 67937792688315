import {
  type TagSelectionProps,
  type TotalVolumeTrend,
  type VolumeByArticle,
  type VolumeByArticleGroup,
} from '@stenarecycling/customer-portal-types';
import { formatNumberToCurrency, formatNumberToLocale, graphBarColors } from '../../utils';

export const makeTagsVolumeArticles = (
  data: VolumeByArticle[] | VolumeByArticleGroup[],
  selectedDate: [string, string],
): TagSelectionProps => {
  const isEmptyData = data.length === 0;
  const totalKg = isEmptyData ? 0 : data[0].totalQuantityKg;
  const totalAmount = isEmptyData ? 0 : data[0].totalAmount;
  const currencyCode = isEmptyData ? 'SEK' : data[0].currencyCode;
  const formattedTotalKg = formatNumberToLocale(totalKg);
  const formattedTotalAmount = formatNumberToCurrency(totalAmount, currencyCode);

  let label = `${formattedTotalKg} kg`;

  if (totalAmount) label += `, ${formattedTotalAmount}`;

  return {
    date: {
      dateFrom: selectedDate[0],
      dateTo: selectedDate[1],
    },
    tags: [
      {
        label,
        circleColor: graphBarColors[0],
      },
    ],
  };
};
export const makeTagsTotalVolumeTrend = (
  data: TotalVolumeTrend[],
  selectedDate: [string, string],
): TagSelectionProps => {
  const values = data.map((item) => item.quantityKg);
  const sumValues = values.reduce((sum, val) => sum + val, 0);

  return {
    date: {
      dateFrom: selectedDate[0],
      dateTo: selectedDate[1],
    },
    tags: [
      {
        label: ` ${formatNumberToLocale(sumValues)} Kg`,
        circleColor: graphBarColors[0],
      },
    ],
  };
};
