import { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import { routePathsOrderPickup } from 'core/lib/routePaths/pickupsRoutePaths';
import { RouteLoader } from '#components/Loader/RouteLoader';

const OrderPickup = lazy(() => import('order-pickup-desktop/central-routes/pickups-routes'));

const PickupsRoute = lazy(() =>
  import('order-pickup-desktop/central-routes/pickups-routes').then((module) => ({
    default: module.PickupsRoute,
  })),
);

const PickupsConfirmationRoute = lazy(() =>
  import('order-pickup-desktop/central-routes/pickups-routes').then((module) => ({
    default: module.ConfirmationRoute,
  })),
);

const BatteryPickupRoute = lazy(() =>
  import('order-pickup-desktop/central-routes/pickups-routes').then((module) => ({
    default: module.BatteryPickupRoute,
  })),
);

const RequestBatteryPickupRoute = lazy(() =>
  import('order-pickup-desktop/central-routes/pickups-routes').then((module) => ({
    default: module.RequestBatteryPickupRoute,
  })),
);

const BatteryConfirmationRoute = lazy(() =>
  import('order-pickup-desktop/central-routes/pickups-routes').then((module) => ({
    default: module.BatteryConfirmationRoute,
  })),
);

const PreviousOrdersRoute = lazy(() =>
  import('order-pickup-desktop/central-routes/pickups-routes').then((module) => ({
    default: module.PreviousOrdersRoute,
  })),
);

const PickupScheduleRoute = lazy(() =>
  import('order-pickup-desktop/central-routes/pickups-routes').then((module) => ({
    default: module.PickupsScheduleRoute,
  })),
);

const DeclarationRoute = lazy(() =>
  import('order-pickup-desktop/central-routes/pickups-routes').then((module) => ({
    default: module.DeclarationRoute,
  })),
);

const RentedContainersRoute = lazy(() =>
  import('order-pickup-desktop/central-routes/pickups-routes').then((module) => ({
    default: module.RentedContainersRoute,
  })),
);

export const PickupsRouteTree = (
  <Route
    path={routePathsOrderPickup.ORDER_PICKUP}
    element={
      <Suspense fallback={<RouteLoader />}>
        <OrderPickup />
      </Suspense>
    }
  >
    <Route index element={<PickupsRoute />} />
    <Route path={routePathsOrderPickup.CONFIRMATION}>
      <Route path=":confirmationId" element={<PickupsConfirmationRoute />} />
    </Route>
    <Route path={routePathsOrderPickup.LI_ION_BATTERIES}>
      <Route index element={<BatteryPickupRoute />} />
      <Route
        path={routePathsOrderPickup.LI_ION_BATTERIES_REQUEST_PICKUP}
        element={<RequestBatteryPickupRoute />}
      />
      <Route
        path={routePathsOrderPickup.LI_ION_BATTERIES_CONFIRMATION}
        element={<BatteryConfirmationRoute />}
      />
    </Route>
    <Route path={routePathsOrderPickup.PREVIOUS_ORDERS} element={<PreviousOrdersRoute />} />
    <Route path={routePathsOrderPickup.PICKUP_SCHEDULE} element={<PickupScheduleRoute />} />
    <Route path={routePathsOrderPickup.DECLARATION} element={<DeclarationRoute />} />
    <Route path={routePathsOrderPickup.RENTED_CONTAINERS} element={<RentedContainersRoute />} />
  </Route>
);

export const preloadPickups = () => {
  //Import default export to trigger preload
  void import('order-pickup-desktop/central-routes/pickups-routes');
};
