export const CountryEnum = {
  SE: 'SE',
  NO: 'NO',
  DK: 'DK',
  FI: 'FI',
  PL: 'PL',
} as const;

export type CountryEnumType = (typeof CountryEnum)[keyof typeof CountryEnum];

export type CountryCode = 'SE' | 'DK' | 'NO' | 'FI' | 'PL';
