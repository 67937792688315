import type { BasicProfile } from '../Profile';
import type { MenuService } from '../Services';

export const userIsUserAdminForAnyPartner = (profile: BasicProfile | null) => {
  if (!profile) {
    return false;
  }

  return (
    profile.aggregatedPermissions.roles.superUser ||
    profile.aggregatedPermissions.roles.externalAdmin
  );
};

export const getUsersServices = (profile: BasicProfile, allServices: MenuService[]) => {
  if (profile.aggregatedPermissions.roles.superUser) {
    return allServices;
  }

  const customServiceNames: string[] = [];

  if (profile.aggregatedPermissions.services.followUp) {
    customServiceNames.push('services.follow.up.name');
  }

  if (profile.aggregatedPermissions.services.orderPickup) {
    customServiceNames.push('services.order.pickup.name');
  }

  const customServices = allServices.filter((s) => customServiceNames.includes(s.name));

  return customServices;
};
