import { useEffect, useState } from 'react';
import { articleGroupsToListDropdown } from '@stenarecycling/customer-portal-utils';
import {
  type ArticleGroup,
  type ArticleListDropdownItem,
} from '@stenarecycling/customer-portal-types';
import { getListItemsFlat } from '../../components/Input/DropdownV2/hooks/useDropdownValues/utils';
import { articleSearchItems } from './utils/customArticleSearch';

export type SelectArticlesProps = {
  articlesLoading: boolean;
  articles: ArticleGroup[];
  onSelectArticles: (articles: string[]) => void;
  onSelectDeclarations: (declarations: string[]) => void;
  selectedArticles: string[];
  selectedDeclarations: string[];
  disabled?: boolean;
};

export type UseSelectArticlesProps = Pick<
  SelectArticlesProps,
  'articles' | 'onSelectArticles' | 'onSelectDeclarations'
> & {
  hideDeclarations?: boolean;
};

export const useSelectArticles = ({
  articles,
  onSelectArticles,
  onSelectDeclarations,
  hideDeclarations = false,
}: UseSelectArticlesProps) => {
  const [items, setItems] = useState<ArticleListDropdownItem[]>([]);

  useEffect(() => {
    const newArticles = articleGroupsToListDropdown(articles, hideDeclarations);

    if (JSON.stringify(newArticles) !== JSON.stringify(items)) {
      setItems(newArticles);
    }
  }, [articles, items, hideDeclarations]);

  const handleOnChange = (selectedValues: string[]) => {
    console.log('selectedValues', selectedValues);
    const selectedItems = getListItemsFlat<ArticleListDropdownItem>(items, selectedValues);
    const selectedDeclarationCodes = hideDeclarations
      ? []
      : selectedItems
          .filter((item: ArticleListDropdownItem) => item.meta?.valueType === 'declaration')
          .map((item) => item.value);

    const selectedArticleCodes = selectedItems
      .filter((item: ArticleListDropdownItem) => {
        const isArticle = item.meta?.valueType === 'article';
        const allDeclarationsSelected =
          item.children.length === 0 ||
          item.children.every((child) => selectedDeclarationCodes.includes(child.value));

        // Only include articleCode if all child-declarations are selected or if hideDeclarations is true
        return hideDeclarations || (isArticle && allDeclarationsSelected);
      })
      .map((item) => item.value);

    if (!hideDeclarations) {
      onSelectDeclarations(selectedDeclarationCodes);
    }
    onSelectArticles(selectedArticleCodes);
  };

  return { items, handleOnChange, articleSearchItems };
};
