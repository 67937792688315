import { type InputHTMLAttributes, type ReactNode, useEffect, useState } from 'react';

import { Check } from '../../Icons/Check';
import { Minus } from '../../Icons/Minus';

import { colors } from '../../../themes';
import {
  CheckboxContainer,
  Description,
  Label,
  LabelDiv,
  LabelWrapper,
  StyledCheckbox,
  StyledInput,
  OnClickElement,
  HoverCheckboxWrapper,
} from './style';

export type CheckboxProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
  checked?: boolean;
  label?: string;
  description?: string;
  disabled?: boolean;
  semiChecked?: boolean;
  children?: ReactNode;
  id?: string;
  value?: string | number;
  onChange?: (checked: boolean) => void;
};

export const Checkbox = ({
  checked,
  label,
  description,
  disabled,
  semiChecked,
  onChange,
  children,
  id,
  value,
  ...rest
}: CheckboxProps) => {
  const [isChecked, setIsChecked] = useState(checked || false);
  const [isSemiCheck, setIsSemiChecked] = useState(semiChecked);

  useEffect(() => {
    setIsChecked(checked ?? false);
  }, [checked]);

  useEffect(() => {
    setIsSemiChecked(semiChecked);
  }, [semiChecked]);

  const toggleChecked = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (onChange) {
      onChange(!isChecked);
    }

    // Only toggle if the checkbox is uncontrolled
    if (checked === undefined) {
      if (!isSemiCheck) {
        setIsChecked(!isChecked);
      }
    }
  };

  return (
    <CheckboxContainer>
      <OnClickElement onClick={!disabled ? toggleChecked : undefined}>
        <StyledCheckbox disabled={disabled}>
          {!isChecked && !isSemiCheck && !disabled && (
            <HoverCheckboxWrapper className="hover-checkbox">
              <Check width="100%" height="100%" color={colors.monochrome.grey40} />
            </HoverCheckboxWrapper>
          )}

          {isChecked && <Check width="100%" height="100%" color={colors.base.black} />}
          {!isChecked && isSemiCheck && (
            <Minus width="100%" height="100%" color={colors.base.black} />
          )}
        </StyledCheckbox>

        <StyledInput
          data-testid="checkbox"
          id={id}
          type="checkbox"
          checked={isChecked}
          disabled={disabled}
          readOnly
          value={value}
          {...rest}
        />
        {label && (
          <LabelWrapper>
            <Label htmlFor={id}>{label}</Label>
            {description && <Description>{description}</Description>}
          </LabelWrapper>
        )}

        {children && (
          <LabelWrapper>
            <LabelDiv>{children}</LabelDiv>
            {description && <Description>{description}</Description>}
          </LabelWrapper>
        )}
      </OnClickElement>
    </CheckboxContainer>
  );
};
