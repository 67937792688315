import {
  type CostByArticleGroupOther,
  type FinancialsTrend,
  type RevenueCostsByArticleGroup,
  type TagSelectionProps,
  type TransportTrend,
} from '@stenarecycling/customer-portal-types';
import { formatNumberToCurrency, formatNumberToLocale, graphBarColors } from '../../utils';

export const makeTagsTotalFinancialTrend = (
  data: FinancialsTrend[] | RevenueCostsByArticleGroup[] | CostByArticleGroupOther[],
  selectedDate: [string, string],
  t: (key: string) => string,
): TagSelectionProps => {
  if (data.length === 0) return { tags: [] };

  const revenueAmountEntries = data.map((item) => item.revenueAmount);
  const costAmountEntries = data.map((item) => item.costAmount);

  const sumRevenueAmount = revenueAmountEntries.reduce((sum, val) => sum + val, 0);
  const sumCostAmount = costAmountEntries.reduce((sum, val) => sum + val, 0);
  const currencyCode = data[0].currencyCode;

  return {
    date: {
      dateFrom: selectedDate[0],
      dateTo: selectedDate[1],
    },
    tags: [
      {
        label: `${t('chart.label.income')} ${formatNumberToCurrency(
          sumRevenueAmount,
          currencyCode,
        )} . ${t('chart.label.cost')} ${formatNumberToCurrency(
          sumCostAmount,
          currencyCode,
        )} . ${t('chart.label.sum')} ${formatNumberToCurrency(
          sumRevenueAmount + sumCostAmount,
          currencyCode,
        )}`,
        circleColor: graphBarColors[0],
      },
    ],
  };
};
export const makeTagsFinanceRevenueTrendTransportCost = (
  data: TransportTrend[],
  selectedDate: [string, string],
): TagSelectionProps => {
  const costAmountEntries = data.map((item) => item.transportAmount);
  const sumCostAmount = costAmountEntries.reduce((sum, val) => sum + val, 0);
  const currencyCode = data[0]?.currencyCode ?? '';

  return {
    date: {
      dateFrom: selectedDate[0],
      dateTo: selectedDate[1],
    },
    tags: [
      {
        label: `${formatNumberToLocale(sumCostAmount)} ${currencyCode}`,
        circleColor: graphBarColors[0],
      },
    ],
  };
};
