import {
  type GraphDataFromApiVariables,
  type GraphTableDataI,
  type TranslateFunc,
} from '@stenarecycling/customer-portal-types';

const getTableTitle = (t: (key: string) => string, key: string, category: string) => {
  if (category === 'volume' || category === 'environment')
    return t('analysisPage.table.title.in.summary') + ' ' + key;

  return key;
};

type DataSortI = {
  value: string | undefined;
  column: string;
};

export const combineCompareTables = ({
  tables,
  input,
  t,
}: {
  tables: GraphTableDataI[];
  input: GraphDataFromApiVariables;
  t: TranslateFunc;
}): GraphTableDataI[] => {
  if (tables.length === 0) return [];
  let allTables = [...tables];

  const comparePairSize = allTables.length;
  let allYAxisLabels: string[] = [];

  // Fix column names, if column is empty, use the columnname at same column-index in other table
  allTables = allTables.map((table) => {
    const columns = [...table.columns].map((column, columnIndex) => {
      if (column.length !== 0) return column;

      const tableWithColumnPresent = allTables.find((table) => {
        return table.columns[columnIndex].length !== 0;
      });

      return tableWithColumnPresent?.columns[columnIndex] ?? '';
    });

    return {
      ...table,
      columns,
    };
  });

  // Flatten all tables into one array, with key value pairs
  const keyVals = allTables.map((table) => {
    return table.columns.map((column, colIndex) => {
      return {
        column,
        values: table.rows.map((row) => ({
          value: row[colIndex].value,
        })),
      };
    });
  });

  // Add yAxisLabels to each value, this is used as a lookup table for values later
  const keyValsWithAxisKey = keyVals.map((keyVal) => {
    const yAxisLabels = keyVal[0].values.map((value) => value.value);

    allYAxisLabels = Array.from(new Set([...allYAxisLabels, ...yAxisLabels]));

    return keyVal.map((key) => {
      const values = key.values.map((value, valueIndex) => ({
        value: value.value,
        yAxisRow: yAxisLabels[valueIndex],
      }));

      return {
        column: key.column,
        values,
      };
    });
  });

  // Add undefined values to the rows, so the row length is always correct, datasets might fewer rows than others
  const values = allYAxisLabels.map((yAxis) => {
    const rows = keyValsWithAxisKey.map((keyVal) => {
      // remove first column, as it is the yAxisLabel and should not be compared in the table.
      const keyValWithoutYAxis = keyVal.slice(1);

      return keyValWithoutYAxis.map((key) => {
        const value = key.values.find((value) => value.yAxisRow === yAxis);

        return {
          value: value?.value,
          column: key.column,
        };
      });
    });

    return {
      yAxis,
      rows,
    };
  });

  const columnSort = new Map();

  // Split upp the values into different arrays, based on the column name,
  values.forEach((value) => {
    value.rows.forEach((row) => {
      row.forEach((innerRow) => {
        const val = columnSort.get(innerRow.column);

        if (val) columnSort.set(innerRow.column, [...val, innerRow]);
        else columnSort.set(innerRow.column, [innerRow]);
      });
    });
  });

  const data: Record<string, DataSortI[][]> = {};

  // Make compare pairs of data, based on the comparePairSize
  for (const [key, dataArr] of columnSort) {
    const comparePairs: DataSortI[][] = [];

    while (dataArr.length) {
      const comparePair = dataArr.splice(0, comparePairSize);

      comparePairs.push(comparePair);
    }

    data[key] = comparePairs;
  }

  const firstColumnName = allTables[0].columns[0];
  const firstColumn = allYAxisLabels.map((yAxis) => ({
    value: yAxis,
    column: firstColumnName,
  }));

  // Add first column values to data
  for (const key in data) {
    const dataArr = data[key];

    data[key] = dataArr.map((comparePair, index) => {
      return [firstColumn[index], ...comparePair];
    });
  }

  const newTables: GraphTableDataI[] = [];
  // Use labels as columns
  const newColumns = allTables.map((table) => table.label);

  // Create new tables based on the data
  for (const key in data) {
    const dataArr = data[key];
    const firstColumn = data[key][0][0].column;
    const rows = dataArr.map((comparePair) => {
      return comparePair.map(({ value }) => ({ value: value ?? '' }));
    });

    const newTable: GraphTableDataI = {
      columns: [firstColumn, ...newColumns].flat(),
      rows,
      title: getTableTitle(t, key, input.category),
      label: '',
    };

    newTables.push(newTable);
  }

  return newTables;
};
