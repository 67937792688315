import { Suspense, lazy } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { routePathsAdmin } from 'core/lib/routePaths/adminRoutePaths';
import { RouteLoader } from '#components/Loader/RouteLoader';

const Admin = lazy(() => import('user-management/central-routes/admin-routes'));

const HomeRoute = lazy(() =>
  import('user-management/central-routes/admin-routes').then((module) => ({
    default: module.HomeRoute,
  })),
);

const UsersRoute = lazy(() =>
  import('user-management/central-routes/admin-routes').then((module) => ({
    default: module.UsersRoute,
  })),
);

const InviteUserRoute = lazy(() =>
  import('user-management/central-routes/admin-routes').then((module) => ({
    default: module.InviteUserRoute,
  })),
);

const UserDetailRoute = lazy(() =>
  import('user-management/central-routes/admin-routes').then((module) => ({
    default: module.UserDetailRoute,
  })),
);

const AddLocationRoute = lazy(() =>
  import('user-management/central-routes/admin-routes').then((module) => ({
    default: module.AddLocationRoute,
  })),
);

const LocationsRoute = lazy(() =>
  import('user-management/central-routes/admin-routes').then((module) => ({
    default: module.LocationsRoute,
  })),
);

const RequestNewLocationRoute = lazy(() =>
  import('user-management/central-routes/admin-routes').then((module) => ({
    default: module.RequestNewLocationRoute,
  })),
);

const RequestNewLocationConfirmationRoute = lazy(() =>
  import('user-management/central-routes/admin-routes').then((module) => ({
    default: module.RequestNewLocationConfirmationRoute,
  })),
);

const LocationDetailRoute = lazy(() =>
  import('user-management/central-routes/admin-routes').then((module) => ({
    default: module.LocationDetailRoute,
  })),
);

const SubscriptionsRoute = lazy(() =>
  import('user-management/central-routes/admin-routes').then((module) => ({
    default: module.SubscriptionsRoute,
  })),
);

const ProfileRoute = lazy(() =>
  import('user-management/central-routes/admin-routes').then((module) => ({
    default: module.ProfileRoute,
  })),
);

export const AdminRouteTree = (
  <Route
    path={routePathsAdmin.HOME}
    element={
      <Suspense fallback={<RouteLoader />}>
        <Admin />
      </Suspense>
    }
  >
    <Route index path={routePathsAdmin.HOME} element={<HomeRoute />} />
    <Route path={routePathsAdmin.USERS}>
      <Route index element={<UsersRoute />} />
      <Route path={routePathsAdmin.INVITE} element={<InviteUserRoute />} />
      <Route path={routePathsAdmin.ADD_LOCATION} element={<AddLocationRoute />} />
      <Route path={routePathsAdmin.USER_DETAILS} element={<UserDetailRoute />} />
    </Route>
    <Route path={routePathsAdmin.LOCATIONS}>
      <Route index element={<LocationsRoute />} />
      <Route path={routePathsAdmin.REQUEST_NEW_LOCATION}>
        <Route index element={<RequestNewLocationRoute />} />
        <Route
          path={routePathsAdmin.REQUEST_NEW_LOCATION_CONFIRMATION}
          element={<RequestNewLocationConfirmationRoute />}
        />
      </Route>
      <Route path={routePathsAdmin.LOCATION_DETAILS} element={<LocationDetailRoute />} />
    </Route>
    <Route path={routePathsAdmin.SUBSCRIPTIONS} element={<SubscriptionsRoute />} />
    <Route path={routePathsAdmin.PROFILE}>
      <Route index element={<Navigate to={'details'} replace={true} />} />
      <Route path={routePathsAdmin.PROFILE_SUBS} element={<ProfileRoute />} />
    </Route>
  </Route>
);

export const preloadAdmin = () => {
  //Import default export to trigger preload
  void import('user-management/central-routes/admin-routes');
};
