import {
  GraphComparingTypesI,
  type GraphDataFromApiVariables,
  type PartnerTreeNodeV2,
  type TagSelectionProps,
} from '@stenarecycling/customer-portal-types';
import { getMappedGraphColorForTags, getPickupName, graphLineColors } from '../../../utils';
import { findPartnerByDwKey } from '../../../../partner';

export const combineCompareTags = ({
  tags,
  input,
  compareType,
  pickupPoints,
}: {
  tags: TagSelectionProps[];
  input: GraphDataFromApiVariables;
  compareType: GraphComparingTypesI;
  pickupPoints: PartnerTreeNodeV2[];
}): TagSelectionProps[] => {
  const getTagColor = getMappedGraphColorForTags();

  if (compareType === GraphComparingTypesI.PERIOD) {
    // Change colors for tags.
    return tags.map((tag) => {
      const { tags, ...rest } = tag;

      const mappedTags = tags.map((tag) => {
        const { circleColor } = tag;
        const { label } = tag;

        const color = getTagColor(graphLineColors.includes(circleColor), true, compareType);

        return {
          label,
          circleColor: color,
        };
      });

      return {
        tags: mappedTags,
        ...rest,
      };
    });
  } else {
    // Combine all tags into 1 level, add BP name, change colors
    const newTags = tags
      .map((tag, tagIndex) => {
        const partner = findPartnerByDwKey(input.businessPartners[tagIndex], pickupPoints);

        return tag.tags.map((tag) => {
          // Remap circleColors
          const { circleColor } = tag;

          const color = getTagColor(graphLineColors.includes(circleColor), true, compareType);

          return {
            label: `${getPickupName(partner)} ${tag.label}`,
            circleColor: color,
          };
        });
      })
      .flat();

    return [
      {
        tags: newTags,
        date: tags[0].date,
      },
    ];
  }
};
