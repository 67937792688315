import { z } from 'zod';

const BatteryAssessmentBaseSchema = z.object({
  selectedLocationId: z.string(),
  conditionSelection: z.union([z.literal('stable'), z.literal('notStableOrUnknown'), z.null()]),
  condition: z.union([z.literal('stable'), z.literal('damaged'), z.literal('critical'), z.null()]),
  type: z.union([z.literal('cells'), z.literal('pack'), z.literal('module'), z.null()]),
  applicationField: z
    .union([
      z.literal('ev'),
      z.literal('lmt'),
      z.literal('sli'),
      z.literal('sbess'),
      z.literal('portable'),
      z.literal('dont_know'),
    ])
    .superRefine((value, ctx) => {
      if (value === 'portable') {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'portable.not.available',
        });
      }
      if (value === 'dont_know') {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'dont.know',
        });
      }
    }),
  netWeight: z.number().positive().lte(100000),
  quantity: z.number().positive().lte(100000),
  packaging: z
    .union([
      z.literal('stable_original_box_pallet'),
      z.literal('stable_sturdy_outer_packaging'),
      z.literal('stable_y_certified'),
      z.literal('damaged_more_than_30kg'),
      z.literal('damaged_less_than_30kg'),
      z.literal('critical'),
    ])
    .nullable(),
  desiredPickupDate: z.string().date(),
  referenceNumber: z.string().optional(),
  batteryReference: z.array(z.string()).optional(),
  fileAttachments: z
    .array(
      z.object({
        id: z.string(),
        fileName: z.string(),
      }),
    )
    .optional(),
  contactPerson: z.string().optional(),
  comment: z.string().optional(),
});

const CriticalDamageAssessmentSchema = z.object({
  storedInWater: z.boolean().nullable(),
  anySmells: z.boolean().nullable(),
  anyRiskOfFire: z.boolean().nullable(),
  anySignsOfLeakingFluids: z.boolean().nullable(),
  anyInternalHeatGeneration: z.boolean().nullable(),
});

const SevereDamageAssessmentSchema = z.object({
  anyMeltedOrBurned: z.boolean().nullable(),
  anyDentsCracks: z.boolean().nullable(),
  anySignsOfSwelling: z.boolean().nullable(),
  outdoorStorage: z.boolean().nullable(),
  identifiedAsDefective: z.boolean().nullable(),
});

const DimensionsSchema = z.object({
  length: z.number().positive().lte(1000),
  width: z.number().positive().lte(1000),
  height: z.number().positive().lte(1000),
});

const EstimatedQuantityBaseSchema = z.object({
  knowEstimatedQuantityOfPackaging: z.boolean().nullable(),
});

const KnowEstimatedQuantityOfPackagingSchema = EstimatedQuantityBaseSchema.extend({
  knowEstimatedQuantityOfPackaging: z.literal(true),
  estimatedQuantityOfPackaging: z.number().positive().lte(1000),
});

const DontKnowEstimatedQuantityOfPackagingSchema = EstimatedQuantityBaseSchema.extend({
  knowEstimatedQuantityOfPackaging: z.literal(false).nullable(),
  dimensions: DimensionsSchema,
});

const PackagingSchemaBase = z.object({
  havePackaging: z.boolean().nullable(),
});

const NotHavePackagingSchema = PackagingSchemaBase.extend({
  havePackaging: z.literal(false).nullable(),
  estimatedQuantity: z.discriminatedUnion('knowEstimatedQuantityOfPackaging', [
    KnowEstimatedQuantityOfPackagingSchema,
    DontKnowEstimatedQuantityOfPackagingSchema,
  ]),
});

const HavePackagingSchema = PackagingSchemaBase.extend({
  havePackaging: z.literal(true),
  estimatedQuantityOfPackaging: z.number().positive().lte(1000),
});

const PackagingQuantitySchema = z.discriminatedUnion('havePackaging', [
  HavePackagingSchema,
  NotHavePackagingSchema,
]);

const StableBatteryAssessmentSchema = BatteryAssessmentBaseSchema.extend({
  condition: z.literal('stable'),
  sturdyCasing: z.boolean().nullable(),
  packagingQuantity: PackagingQuantitySchema,
});

const DamagedBatteryAssessmentSchema = BatteryAssessmentBaseSchema.extend({
  condition: z.literal('damaged'),
  criticalDamageAssessment: CriticalDamageAssessmentSchema,
  severeDamageAssessment: SevereDamageAssessmentSchema,
  packagingQuantity: PackagingQuantitySchema,
});

const CriticalBatteryAssessmentSchema = BatteryAssessmentBaseSchema.extend({
  condition: z.literal('critical'),
  criticalDamageAssessment: CriticalDamageAssessmentSchema,
  batteryModel: z.string().optional(),
  formFactor: z.union([
    z.literal('cylindrical'),
    z.literal('prismatic'),
    z.literal('pouch'),
    z.literal('unknown'),
  ]),
  cellChemistry: z.union([
    z.literal('nmc 6-2-2'),
    z.literal('nmc 8-1-1'),
    z.literal('nmc 9-0.5-0.5'),
    z.literal('nmc (other/unspecified)'),
    z.literal('nca'),
    z.literal('lco'),
    z.literal('lfp'),
    z.literal('lto (anode chemistry)'),
    z.literal('unknown'),
  ]),
  nominalCapacity: z.number().positive().lte(1000),
  dimensionsPerBatteryModel: DimensionsSchema,
  packagingQuantity: PackagingQuantitySchema,
});

const UnknownBatteryAssessmentSchema = BatteryAssessmentBaseSchema.extend({
  condition: z.null(),
  criticalDamageAssessment: CriticalDamageAssessmentSchema,
  severeDamageAssessment: SevereDamageAssessmentSchema,
});

export const BatteryAssessmentSchema = z.discriminatedUnion('condition', [
  StableBatteryAssessmentSchema,
  DamagedBatteryAssessmentSchema,
  CriticalBatteryAssessmentSchema,
  UnknownBatteryAssessmentSchema,
]);

export const BatteryOrderReferenceSchema = z.object({
  orderReference: z.string().uuid(),
  createdAt: z.string().datetime(),
  requestedBy: z.string().email(),
  batteryAssessment: BatteryAssessmentSchema,
});

export const BatteryOrderResponseSchema = BatteryOrderReferenceSchema.extend({
  location: z.object({
    label: z.string(),
    description: z.string(),
  }),
});

export type PackagingQuantity = z.infer<typeof PackagingQuantitySchema>;

export type CriticalDamageAssessment = z.infer<typeof CriticalDamageAssessmentSchema>;
export type SevereDamageAssessment = z.infer<typeof SevereDamageAssessmentSchema>;

export type StableBatteryAssessment = BatteryAssessment & { condition: 'stable' };
export type DamagedBatteryAssessment = BatteryAssessment & { condition: 'damaged' };
export type CriticalBatteryAssessment = BatteryAssessment & { condition: 'critical' };

export type BatteryAssessment = z.infer<typeof BatteryAssessmentSchema>;

export type BatteryOrderReference = z.infer<typeof BatteryOrderReferenceSchema>;
export type BatteryOrderResponse = z.infer<typeof BatteryOrderResponseSchema>;

export const PostBatterySupportSchema = z.object({
  reason: z.union([
    z.literal('general_assistance'),
    z.literal('battery_assessment'),
    z.literal('packaging'),
    z.literal('other'),
  ]),
  message: z.string().max(500),
  selectedLocation: z.string().optional(),
  country: z.union([
    z.literal('SE'),
    z.literal('NO'),
    z.literal('DK'),
    z.literal('FI'),
    z.literal('PL'),
  ]),
});

export type PostBatterySupport = z.infer<typeof PostBatterySupportSchema>;
