import {
  type ArticleGroup,
  type ArticleListDropdownItem,
} from '@stenarecycling/customer-portal-types';

export const articleGroupsToListDropdown = (
  articleGroups: ArticleGroup[],
  excludeDeclarations?: boolean,
): ArticleListDropdownItem[] =>
  articleGroups
    .sort((a, b) => a.groupName.localeCompare(b.groupName, 'sv'))
    .map((articleGroup) => ({
      type: 'group',
      value: articleGroup.groupName,
      label: articleGroup.groupName,
      children: articleGroup.children
        .sort((a, b) => a.articleName.localeCompare(b.articleName, 'sv'))
        .map((article) => ({
          value: article.articleCode,
          label: article.articleName,
          meta: {
            description: article.articleCode,
            valueType: 'article',
          },
          type: 'value',
          children: excludeDeclarations
            ? []
            : article.children
                .sort((a, b) => a.declarationName.localeCompare(b.declarationName, 'sv'))
                .map((declaration) => ({
                  value: declaration.declarationCode,
                  label: declaration.declarationName,
                  meta: {
                    description: declaration.declarationCode,
                    valueType: 'declaration',
                  },
                  type: 'value',
                  children: [],
                })),
        })),
    }));

export const getArticleCodesFromArticleGroups = (articleGroups: ArticleGroup[]) => {
  return articleGroups.reduce<string[]>((acc, ag) => {
    return [...acc, ...ag.children.flatMap((a) => [a.articleCode])];
  }, []);
};
export const getAllCodesFromArticleGroups = (articleGroups: ArticleGroup[]) => {
  // Return both articdoes and declaration codes, as a flat array, make sure count is correct and no duplicates
  const allCodes = articleGroups.reduce<string[]>((acc, ag) => {
    return [
      ...acc,
      ...ag.children.flatMap((a) => a.children.flatMap((d) => [d.declarationCode, a.articleCode])),
    ];
  }, []);

  //make no duplicates
  return [...new Set(allCodes)];
};

export const getDeclarationCodesFromArticleGroups = (artcleGroups: ArticleGroup[]) => {
  return artcleGroups.reduce<string[]>((acc, ag) => {
    return [...acc, ...ag.children.flatMap((a) => a.children.flatMap((d) => [d.declarationCode]))];
  }, []);
};
