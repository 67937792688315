export const formatNumberToLocale = (
  number: number | string,
  options: Record<string, unknown> = {},
  locale = 'sv-SE',
): string => {
  const parsedNum = parseFloat(`${number}`);

  if (isNaN(parsedNum)) return number as string;

  if (options.style === 'percent' && parsedNum < 0.00005 && parsedNum > 0) {
    return `< ${(0.01).toLocaleString(locale)} %`;
  }

  if (options.removeTrailingZeros) {
    options.trailingZeroDisplay = 'stripIfInteger';
  }

  const numberString = parsedNum.toLocaleString(locale, {
    minimumFractionDigits: options.style === 'percent' ? 2 : 0,
    maximumFractionDigits: 2,
    ...options,
  });

  return numberString;
};

export const formatNumberToCurrency = (
  number: number | string,
  currencyCode: string,
  locale?: string,
  currencyDisplay?: string,
) => {
  if (currencyCode === '') return '';

  const currencyString = formatNumberToLocale(
    number,
    {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 2,
      removeTrailingZeros: true,
      currencyDisplay,
    },
    locale,
  );

  if (currencyCode === 'NOK' && currencyString) {
    return currencyString.replace('Nkr', 'NOK');
  }

  return currencyString;
};

export const formatNumberToKgUnit = (
  number: number | string,
  removeUnit = false,
  maximumFractionDigits = 2,
) => {
  const options = {
    style: 'unit',
    unit: 'kilogram',
    minimumFractionDigits: 2,
    maximumFractionDigits,
    removeTrailingZeros: true,
  };

  if (removeUnit) {
    options.style = 'decimal';
  }

  const value = formatNumberToLocale(number, options);

  return value;
};
