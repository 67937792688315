export type SearchFilter = {
  selectedPartners: string[];
  selectedArticles: string[];
  selectedDeclarations: string[];
  selectedDateRange: [Date, Date];
  selectedUnit: 'kg' | 'ton';
};

export const saveSearchFilter = (userId: string, filterState: SearchFilter) => {
  //Save to session storage
  const key = `search-filter-${userId}`;

  window.sessionStorage.setItem(key, JSON.stringify(filterState));
};

export const getSearchFilter = (userId: string): SearchFilter | null => {
  const key = `search-filter-${userId}`;
  const filter = window.sessionStorage.getItem(key);

  if (filter) {
    return JSON.parse(filter);
  }

  return null;
};
