import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import { WithCustomerPortalApi } from '../../customerPortalApi';
import { getApiUrl } from '../../utils/environment';

const apiUrl = getApiUrl();

type RoleIndustryResponse = {
  availableRoles: string[];
  availableIndustries: string[];
};

export const useRoleIndustry = WithCustomerPortalApi<
  RoleIndustryResponse,
  UseQueryResult<RoleIndustryResponse>
>((fetchFn) => {
  const url = `${apiUrl}/usermanagement/user/role-industry`;

  const query = useQuery({
    queryKey: ['role-industry'],
    enabled: true,
    queryFn: ({ signal }) =>
      fetchFn({
        url,
        options: {
          method: 'GET',
          signal,
        },
      }),
  });

  return query;
});
