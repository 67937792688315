import { createRoot } from 'react-dom/client';
import 'component-library/static/css/base.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Import the generated route tree
import { RouterProvider } from 'react-router-dom';
import { Auth0ProviderWithHistory } from 'core/AuthProvider';
import { GovernanceProvider } from 'core/GovernanceProvider';
import { StrictMode } from 'core/lib/utils/StrictMode';
import { router } from './routes/routeTree';
// Render the app

const root = createRoot(document.getElementById('root') as Element);

const queryClient = new QueryClient();

root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <Auth0ProviderWithHistory>
        <GovernanceProvider sentryDsn="https://f405b60c8a09478ca68cef19d8e84e92@o481031.ingest.sentry.io/4504083514130432">
          <RouterProvider router={router} />
        </GovernanceProvider>
      </Auth0ProviderWithHistory>
    </QueryClientProvider>
  </StrictMode>,
);
